<template>
  <div class="main notice border-bg">
    <div class="border-tit">当前位置 > 首页 > {{ tit }}</div>
    <div class="border-tit2">{{ info.title }}</div>
    <div class="border-tit-info flex">
      <!-- <div><span>浏览次数：</span>60684</div> -->
      <div><span>发布时间：</span>{{ info.create_time }}</div>
    </div>
    <div class="content" v-html="info.content"></div>
  </div>
</template>
<script>
import { articleDetail } from "@/api/apiData";
export default {
  data() {
    return {
      info: "",
      tit: "公示公告",
    };
  },
  mounted() {
    let id = this.$route.query.id;
    let type = this.$route.query.type;
    if (type == 1) {
      this.$bus.emit("navS", 7);
      this.tit = "疑难解答";
    } else {
      this.$bus.emit("navS", 3);
      this.tit = "公示公告";
    }

    articleDetail({ article_id: id }).then((res) => {
      if (res.code == 1) {
        this.info = res.data;
      } else {
        this.$message.error(res.msg);
      }
    });
  },
};
</script>
<style lang="less" scoped>
.notice {
  margin-top: 20px;
}
</style>